<div *ngIf="clientDetails?.name" id="app-header-title">
  <h1 class="app-name text-color themed-text">{{clientDetails?.name}}</h1>
  <p class="app-title text-color themed-text">{{clientDetails?.title}}</p>
</div>
<section class="reset-password reset-password-wrapper">
  <div class="tab-pane fade active in reset-password-content" [class.hide]="!resetPasswordScreen">
    <h1 class="reset-password-msg">Reset password</h1>
    <div class="reset-password-info">Enter a new password for your Taylor & Francis account</div>
    <form class="form-bowl" id="ResetPasswordForm" autocomplete="off">
      <div class="form-group is-empty label-floating mrClass">
        <span for="Password" class="form-label">Password</span>
        <span class="icon-info" tabindex="0" data-toggle="popover" data-trigger="focus" [attr.data-content]="passwordHint"></span>
        <p id="passwordError" hidden>{{ passwordHint }}</p>
        <p id="passwordError2" hidden>{{ passwordHint }}</p>
           <input [type]=" viewPswd == 'icon-eye-blocked' ? 'password': 'text'" class="form-control" id="inputEmail"  
           [(ngModel)]="newPwd" name="password" [attr.aria-invalid]="isPasswordError===true?'true':'false'"
           [attr.aria-describedby]="isPasswordError===true?'passwordError2':'passwordError'"
           [ngClass]="{'inputError': isPasswordError}" 
            (blur)="validatePassword()" (input)="validatePassword()">
            <span class="pwd-view-icon" 
              (click)= "(viewPswd =  viewPswd == 'icon-eye-blocked' ? 'icon-eye' : 'icon-eye-blocked')"
              [ngClass]="viewPswd" (keydown.enter)="(viewPswd =  viewPswd == 'icon-eye-blocked' ? 'icon-eye' : 'icon-eye-blocked')">
           </span>
        <div class = "errorMsg"  [ngClass]="isPasswordError===true?'class1':'class2'">
           <span class="icon-error"></span><span>{{passwordHint}}</span>
        </div>
     </div>

     <div class="form-group is-empty label-floating mrClass">
      <span for="ConfirmPassword" class="form-label">Confirm Password</span>
      <input [type]=" viewcnfPswd == 'icon-eye-blocked' ? 'password': 'text'" class="form-control" id="confirmPwd"  
           [(ngModel)]="cnfPwd" name="password" [attr.aria-invalid]="isConfirmPasswordError===true?'true':'false'"
           [attr.aria-describedby]="isConfirmPasswordError===true?'passwordError2':'passwordError'"
           [ngClass]="{'inputError': isConfirmPasswordError}" 
            (blur)="validatePassword()" (input)="validatePassword()">
            <span class="pwd-view-icon" 
              (click)="(viewcnfPswd =  viewcnfPswd == 'icon-eye-blocked' ? 'icon-eye' : 'icon-eye-blocked')"
              [ngClass]="viewcnfPswd" (keydown.enter)="(viewcnfPswd =  viewcnfPswd == 'icon-eye-blocked' ? 'icon-eye' : 'icon-eye-blocked')">
           </span>          
      <div class="errorMsg" [ngClass]="isConfirmPasswordError===true?'class1':'class2'">
        <span class="icon-error"></span><span>{{newPassMatchCnfPass}}</span>
      </div>
    </div>

      <div class="form-button-access text-center" id="resetPasswordButtonContainer">
        <button color="primary" (click)="resetPassword()" id="resetPasswordButton"
          class="themed-button btn btn-primary btn-block">RESET PASSWORD</button>
      </div>
    </form>
  </div>

  <div class="tab-pane fade active in" id="forgot-password" *ngIf="forgotPasswordScreen">

    <h1 class="forgot-password-msg">Reset password</h1>

    <div class="forgot-password-wrapper">
      <div class="back-button-wrapper links" aria-label="Back to login page" tabindex="0" role="link"
        (click)="goToLogin()" (keydown.enter)="goToLogin()">
        <div class="login-path"> </div>
        <span class="back-link links">Back</span>
      </div>

      <div class="forgot-password-info">Enter the email address that you registered with and we’ll send you 
        instructions on how to reset your password.</div>
      <form class="form-bowl" id="forgotPasswordForm" autocomplete="off">
        <div [ngClass]="{'has-error': (isEmailError), 'is-empty': !(email)}" class="form-group label-floating">
          <span for="inputEmail" class="form-label">Email</span>
          <input type="text" class="form-control" id="inputEmail" [(ngModel)]="email" name="email"
            (input)="invalidateEmpty()" [attr.aria-describedby]="invalidEntry()?'inputErrorMsg':'readError'"
            [ngClass]="{'inputError':isEmailError}" (blur)="validateEmailBlur()">
          <p id="readError" hidden>{{ errorMessage }}</p>
          <span class="input-error" [ngClass]="isEmailError===true?'class1':'class2'" id="inputErrorMsg">
            <span class="icon-error"> </span> <span class="error-position">{{ errorMessage }}</span>
          </span>
        </div>
        <div class="form-button-access text-center" id="forgotPasswordButtonContainer">
          <button (click)="sendResetPasswordLink()" color="primary" id="forgotPasswordContinueButton"
            class="themed-button continue-btn btn btn-primary btn-lg btn-block">Continue</button>
        </div>
      </form>
    </div>
  </div>


  <div class="email-sent-wrapper" *ngIf="emailSentScreen">
    <form class="tab-pane text-center fade active in">
      <div class="image-container">
        <img src="./assets/img/fill-3.png" alt="circle" aria-hidden="true">
        <img class="tick-image" src="./assets/img/fill-1.png" alt="tick" aria-hidden="true">
      </div>
      <h1 class="email-sent-msg">Email Sent</h1>
      <div class="email-sent-info">
        {{sentNotificationMsg}}
      </div>
    </form>
  </div>

  <div class="email-sent-wrapper" *ngIf="resetPasswordSuccessScreen">
    <form class="tab-pane text-center fade active in">
      <div class="image-container">
        <img src="./assets/img/fill-3.png" alt="fill image3">
        <img class="tick-image" src="./assets/img/fill-1.png" alt="fill image1">
      </div>
      <div class="email-sent-msg">Password has been reset successfully</div>
      <div class="redirect-link-container">
        <button class="themed-button btn btn-primary login-btn" (click)="goToLogin()">Login</button>
      </div>
    </form>
  </div>

  <div *ngIf="isTokenExpired" class="reset-link-expired-container">
    <div>
      <em class="icon-error red-icon "></em>
    </div>
    <h1 class="reset-password-msg">Reset unsuccessful</h1>
    <div class="popup-description">
      The link to reset your password has expired.
      <a class="link" href="javascript:;" (click)="sendResetPasswordLink(true)"> Resend link.</a>
    </div>
  </div>
  <top-notification [message]="resentNotificationMsg" [isSuccess]="true" *ngIf="resentLink"></top-notification>
</section>