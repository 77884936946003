import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { AccountsService } from "../../../services/accounts-api/accounts-api.service";
import { AppState } from '../../../app.service';
import { nameValidator, isValidEmail, MAX_CHARACTER_LIMIT } from '@tandfgroup/form-field-validator'
@Component({
  selector: 'my-account',
  templateUrl: 'my-account.component.html',
  styleUrls: ['my-account.component.scss']
})
export class MyAccountComponent {
  displayName = '';
  firstName = '';
  lastName = '';
  email = '';
  username = '';
  institution = '';
  userId = '';
  user: any = {};
  isEmailEditable = false;
  isInstitutionEditable = false;
  isInvalidFirstName = false;
  isInvalidLastName = false;
  isInvalidEmail = false;
  isInvalidInstitution = false;
  errorMessage = '';
  errorMessageFirstName = '';
  errorMessageLastName = '';
  updateInfoMessageDetailsSave = "Your name updated successfully";
  updateInfoMessageBackUpCodesGenerated = "Backup codes downloaded successfully ";
  updateInfoMessage = this.updateInfoMessageDetailsSave;
  showUdateInfoMessage = false;
  mFAEnabled = false;
  editDisabled: boolean = false;
  private subscription: Subscription;
  firstNameMaxLimit: number = null;
  lastNameMaxLimit: number = null;

  constructor(
    private accountsService: AccountsService,
    public appState: AppState
  ) { }

  ngOnInit() {
    this.firstNameMaxLimit = MAX_CHARACTER_LIMIT.firstName;
    this.lastNameMaxLimit = MAX_CHARACTER_LIMIT.lastName;
    this.subscription = this.appState.user$.subscribe((user) => {
      this.user = user;
      this.userId = this.user._id;
      if (user._id) { this.setNames(); }
      this.email = this.user.email;
      this.username = this.user.username;
      this.institution = this.user.organization;
      this.mFAEnabled = this.user.mFAEnabled || false;
      this.editDisabled = this.user.userType == 'office365_user';
    });
  }

  setNames(){
    this.user.firstName = this.user.firstName ? this.user.firstName.trim() : '';
    this.user.lastName = this.user.lastName ? this.user.lastName.trim() : '';
    if(this.user.firstName && this.user.lastName) {
      this.firstName = this.user.firstName;
      this.lastName = this.user.lastName;
    }
    else if(this.user.displayName){
      if(this.user.displayName.includes(',')){
        let displayNameArr = this.user.displayName.split(',').map(e => {return e.trim()});
        this.firstName = displayNameArr[1];
        this.lastName = displayNameArr[0];
        this.displayName = this.firstName + ' ' + this.lastName;
      }
      else {
        let displayNameArr = this.user.displayName.split(' ');
        if (displayNameArr.length === 1) {
          this.firstName = this.user.displayName;
          this.lastName = this.user.displayName;
        }
        else if (displayNameArr.length === 2) {
          this.firstName = displayNameArr[0];
          this.lastName = displayNameArr[1];
        }
        else if (displayNameArr.length > 2) {
          this.firstName = displayNameArr[0];
          this.lastName = displayNameArr.slice(1).join(' ');
        }
      }
    }
    else{
      this.firstName = this.user.firstName ? this.user.firstName : '';
      this.lastName = this.user.lastName ? this.user.lastName : '';
      this.displayName = (this.firstName + ' ' + this.lastName).trim();
    }
    this.displayName = this.user.displayName
  }

  updateAccount(): void {
    this.isInvalidFirstName = false;
    this.isInvalidLastName = false;
    this.isInvalidEmail = false;
    this.isInvalidInstitution = false;
    this.showUdateInfoMessage = false;

    this.firstName = this.firstName.replace(/\s+/g, ' ').trim();
    this.lastName = this.lastName.replace(/\s+/g, ' ').trim();
    this.email = this.email.trim();

    if (this.isEmailEditable && !isValidEmail(this.email)) {
      this.isInvalidEmail = true;
      this.errorMessage = "Must be an email address.";
      document.getElementById('isInvalidEmail').focus();
    }
    this.validateName(this.firstName, 'firstName');
    this.validateName(this.lastName, 'lastName');
    if (this.isInvalidFirstName) {
      document.getElementById('inputFirstName').focus();
      return;
    } else if (this.isInvalidLastName) {
      document.getElementById('inputLastName').focus();
      return;
    }
    if (!this.isInvalidFirstName && !this.isInvalidLastName && !this.isInvalidEmail) {
      this.accountsService.updateAccount(this.userId, {
        firstName: this.firstName.replace(/\s+/g, ' ').trim(),
        lastName: this.lastName.replace(/\s+/g, ' ').trim(),
        displayName: (this.firstName + ' ' + this.lastName).replace(/\s+/g, ' ').trim(),
      })
        .subscribe(
          (response) => {
            this.appState.user$.next(response);
            this.appState.set('user', response);
            this.updateInfoMessage = this.updateInfoMessageDetailsSave;
            this.showUdateInfoMessage = true;
            setTimeout(() => {
              document.getElementById('closeNotification').focus()
            }, 100);
          },
          (err) => {
            console.log('Unable to update user', err);
            this.isInvalidFirstName = true;
            this.isInvalidLastName = true;
            this.errorMessage = err.message;
          }
        );
    }

  }

  validateName(name: string, fieldName: 'firstName' | 'lastName') {
    const fieldUiName = {
      firstName: 'First Name',
      lastName: 'Last Name',
    };
    let errorMessage = '';
    const validateNameRes = nameValidator(name?.trim(), fieldName);
    if (!name?.trim()) {
      errorMessage = `${fieldUiName[fieldName]} cannot be empty`;
    } else if (!validateNameRes.valid) {
      errorMessage = validateNameRes.description || `${fieldUiName[fieldName]} invalid`;
    }
    if (fieldName === 'firstName') {
      this.isInvalidFirstName = !!errorMessage;
      this.errorMessageFirstName = errorMessage || '';
    } else {
      this.isInvalidLastName = !!errorMessage;
      this.errorMessageLastName = errorMessage || '';
    }
  }

  ngOnDestroy() {
    // prevent memory leak by unsubscribing
    this.subscription?.unsubscribe();
  }
  onKeydownEvent(event: any) {
    if (event.srcElement.id === 'inputFirstName') {
      this.isInvalidFirstName = false;
    }
    if (event.srcElement.id === 'inputLastName') {
      this.isInvalidLastName = false;
    }
    if (event.srcElement.id === 'isInvalidEmail') {
      this.isInvalidEmail = false;
    }
    if (event.srcElement.id === 'inputInstitution') {
      this.isInvalidInstitution = false;
    }
  }

}