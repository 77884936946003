
  <button class="icon-cancel pull-right" aria-label="Close" (click)="declineTerms()"></button>
<div class='accept-terms-description'>
  <h1 class="popup-title">Additional information required.</h1>
  <p>
    In order to proceed we require you to agree with our terms and conditions.
  </p>
  <input type="checkbox" [(ngModel)]="isTermsChecked"> I agree to the  <a class='terms-link' target="_blank" [href]="termsOfUseLink">Terms and conditions</a>
  <p *ngIf="errorMsgTerms" style="color: red;">Agreement to Terms & Conditions required</p>
</div>
<div class='button-container'>
  <button class="themed-button btn btn-primary btn-lg" (click)="acceptTerms()">Continue</button>
</div>

