/*
 * Accept terms and condition component.
 * When user with MFA enabled logs in, the user has to verify the code in order to succefully login.
 *
 */
import { Component, Inject } from '@angular/core';
import { Router } from "@angular/router";
import { UrlConfig } from '../../../../url-config';
import { AppState } from '../../../app.service';
import { AuthapiService } from "../../../services/authapi/authapi.service";
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'multi-factor-auth',
  styleUrls: ['./multi-factor-auth.component.scss'],
  templateUrl: './multi-factor-auth.component.html'
})
export class MFAComponent {
  config = new UrlConfig();
  baseURL: string = this.config.getBaseApiUrl();
  clientConfig: any = {};
  mfaEmailCode: string = '';
  errorMessage: string = '';
  authorizeAction: string = this.baseURL + 'user/auth/authorize';
  clientId: string = '';
  authorize: boolean = false;
  userId: string = '';
  state: string = '';
  isError: boolean = false;
  showSentCodeInfoMessage = false;
  sentCodeMessage = "Code sent through email successfully ";
  whiteListDevice: boolean = false;
  isLoading: boolean = false;
  isOrcid: boolean = false;
  showAddPopup: boolean = false;
  optout: boolean = false;
  firstName = '';
  lastName = '';
  email: string = '';
  country: any = {};
  countryExists: boolean = false;

  constructor(private _route: Router, public appState: AppState, private authServices: AuthapiService,
    @Inject(DOCUMENT) private document: Document) {
  }

  ngOnInit() {
    // Get client config
    this.clientConfig = this.config.getClientConfig(this.appState.get('brand'));
    if (Object.keys(this.appState.get('queryParams')).length > 0) {
      this.clientId = this.appState.get('queryParams')['client_id'];
      this.authorize = Boolean(this.appState.get('queryParams')['authorize']);
      this.userId = this.appState.get('queryParams')['userId'];
      this.state = this.appState.get('queryParams')['state'];
      this.isOrcid = this.appState.get('queryParams')['isOrcid'];
      this.showAddPopup = this.appState.get('queryParams')['showAddPopup'] || this.appState.get('user')?.showAddPopup ? this.appState.get('queryParams')['showAddPopup'] || this.appState.get('user')?.showAddPopup : false;
      this.optout = this.appState.get('queryParams')['optOut'] == 'true' || this.appState.get('queryParams')['optOut'] || this.appState.get('user')?.optOut;
      this.firstName = this.appState.get('queryParams')['firstName'] || this.appState.get('user')?.firstName;
      this.lastName = this.appState.get('queryParams')['lastName'] || this.appState.get('user')?.lastName;
      this.email = this.appState.get('queryParams')['email'] || this.appState.get('user')?.email; 
      if((this.appState.get('queryParams')['countryExists'] || this.appState.get('queryParams')['country']) && this.appState.get('queryParams')['country'] !== 'null'){
        this.country['name'] = this.appState.get('queryParams')['country'];
      }
      this.setCountryCode();
    }
  }

  setCountryCode() {
    if(this.appState.get('queryParams')['countryCode'] && this.appState.get('queryParams')['countryCode'] !== 'null') {
      this.country.countryCode = this.appState.get('queryParams')['countryCode'];    }
  }

  // If user accept the terms and condition,
  // Then update the user and navigate to next page.


  verifyMFACode() {
    this.isError = false;
    this.showSentCodeInfoMessage = false;
    if (!this.mfaEmailCode || this.mfaEmailCode.length === 0) {
      this.isError = true;
      this.errorMessage = "Verification code cannot be empty";
      document.getElementById('inputEmail').focus();
    } else {
      this.isLoading = true;
      this.authServices.verifyMFACode(this.userId, this.mfaEmailCode, this.whiteListDevice)
        .subscribe(
          (response) => {
            (async () => {
              await this.decidePageRedirection(response);
            })();
          },
          (err) => {
            this.isLoading = false;
            console.log('Unable to update user', err);
            this.setErrorStateVariables(err.metadata);
          });
    }
  }

  private async decidePageRedirection(response: any) {
    const countryExists = this.setIfCountryExists(response);
    if (this.authorize) {
      //check to show consent page
      if (response.consentGiven != undefined && !response.consentGiven || this.showAddPopup) {
        await this._route.navigate(
          ['/orcid-shibboleth'],
          { queryParams: { authorize: this.authorize, client_id: this.clientId, userId: this.userId, "isTnfLogin": true, countryExists, state: this.state, isOrcid: this.isOrcid, firstName: response?.firstName, lastName: response?.lastName, email: response?.email, country: response?.address?.country, optOut: response?.optOut, showAddPopup: this.showAddPopup, consent: response?.hasAcceptedTerms, countryCode: response?.address?.countryCode } });} 
          else if (response.hasAcceptedTerms != undefined && !response.hasAcceptedTerms) {
        await this._route.navigate(['/accept-terms'], { queryParams: { authorize: this.authorize, client_id: this.clientId, userId: this.userId } })
      } else {
        const authorizeParams = this.appState.get('authorizeParams');
        const authorizeQueryParams = Object.keys(authorizeParams).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(authorizeParams[k])}`).join('&');
        this.setHref(this.authorizeAction + "?" + authorizeQueryParams);
      }
    } else {
      this.appState.user$.next(response);
      this.appState.set('user', response);
      if (response.consentGiven != undefined && !response.consentGiven || this.showAddPopup) {
        await this._route.navigate(['/orcid-shibboleth'], { queryParams: { userId: this.userId, "isTnfLogin": true, client_id: this.clientId, countryExists, state: this.state, isOrcid: this.isOrcid, firstName: response?.firstName, lastName: response?.lastName, email: response?.email, country: response?.address?.country, optOut: response?.optOut, showAddPopup: this.showAddPopup, consent: response?.hasAcceptedTerms, countryCode: response?.address?.countryCode } });
        } else if (response.hasAcceptedTerms) {
        await this._route.navigate(['/accounts']);
        //check to show consent page
      } else {
        await this._route.navigate(['/accept-terms'], { queryParams: { userId: this.userId } });
      }
    }
  }

  setIfCountryExists(user) {
    return user.address?.country?.length >= 2 ? user.address.country : null;
  }

  ResendCode() {
    this.authServices.sendMFACode(this.userId)
      .subscribe(
        (response) => {
          this.showSentCodeInfoMessage = true;
        },
        (err) => {
          console.log('Unable to send code: ', err);
          this.isError = true;
          this.errorMessage = "Unable to send code.";
        }
      );
  }

  private setErrorStateVariables(errorMetadata: any) {
    if (errorMetadata) {
      console.error(errorMetadata);
      this.errorMessage = errorMetadata.message.value;
      this.isError = true;
      document.getElementById('inputEmail').focus();
    }
  }

  setHref(url: string){
    this.document.location.href = url;
  }
}
