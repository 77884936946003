<div class="my-account">
<div class="account-content" role="region" aria-label="Manage Your Account">

  <div class="account-header-wrapper">
    <div class="account-header">
      <h1>Your Account</h1>
      <p>Manage your basic account settings</p>
    </div>
  </div>

  <div class="account-body-wrapper">
    <form class="form-bowl" (ngSubmit)="updateAccount()">

      <div [ngClass]="{'has-error': (isInvalidFirstName), 'is-empty': !(firstName)}" class="form-group label-floating">
        <label for="inputFirstName" class="control-label">First Name</label>
        <input type="text" disabled="{{editDisabled}}" class="form-control" [(ngModel)]="firstName" name="firstName" (keydown)=onKeydownEvent($event)
        id="inputFirstName" autocomplete="off" (blur)="validateName(firstName, 'firstName')" [maxlength]="firstNameMaxLimit">
        <p aria-live="polite" class="text-indent">
          <span class="icon-error input-error-icon" *ngIf="isInvalidFirstName"> </span>
          <span class="input-error-password" *ngIf="isInvalidFirstName">{{ errorMessageFirstName }}</span>
        </p>
      </div>

      <div [ngClass]="{'has-error': (isInvalidLastName), 'is-empty': !(lastName)}" class="form-group label-floating">
        <label for="inputLastName" class="control-label">Last Name</label>
        <input type="text" disabled="{{editDisabled}}" class="form-control" [(ngModel)]="lastName" name="lastName" (keydown)=onKeydownEvent($event)
        id="inputLastName" autocomplete="off" (blur)="validateName(lastName, 'lastName')" [maxlength]="lastNameMaxLimit">
        <p aria-live="polite" class="text-indent">
          <span class="icon-error input-error-icon" *ngIf="isInvalidLastName"> </span>
          <span class="input-error-password" *ngIf="isInvalidLastName">{{ errorMessageLastName }}</span>
        </p>
      </div>

      <div [ngClass]="{'has-error': (isInvalidInstitution), 'is-empty': !(institution)}"
        class="form-group label-floating">
        <label [ngClass]="{'disabled' : !isInstitutionEditable}" for="inputInstitution"
          class="control-label">Institution (optional)</label>
        <input type="text" class="form-control" (keydown)=onKeydownEvent($event) [(ngModel)]="institution"
          name="institution" id="inputInstitution" (focus)="isInvalidInstitution=false"
          disabled="{{!isInstitutionEditable}}">
        <p aria-live="polite" class="text-indent">
          <span class="icon-error input-error-icon" *ngIf="isInvalidInstitution"> </span>
          <span class="input-error" *ngIf="isInvalidInstitution">{{ errorMessage }}</span>

        </p>
      </div>
      
      <div class="form-button">
        <button color="primary" class="themed-button btn btn-primary" id="your_account_save">SAVE</button>
      </div>

    </form>
  </div>

</div>

<top-notification id="Notification" [message]="updateInfoMessage" [isSuccess]="true" aria-live="polite" *ngIf="showUdateInfoMessage" tabindex="0" ></top-notification>

</div>